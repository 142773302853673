@import '../../styles/colors';
@import '../../styles/general';

.donation-item {
  display: block;
  margin-bottom: 1.5rem;
  text-decoration: none;
}

.page-title {
  margin: 0
}

.donation-item-body {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;
  border: 1px solid $white-gray;
  box-shadow: $sm-box-shadow;
  transition: all 120ms;

  &:hover {
    border-color: $light-blue;
  }
}

.donation-item-image-container {
  display: flex;
  flex: 2;
  align-items: center;
  flex-direction: column;
}

.donation-item-image-header {
  width: 200px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.donation-item-image {
  padding: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.donation-item-text-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.donation-item-amount-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  justify-self: center;
  align-self: stretch;
}

.donation-item-amount-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.donation-item-amount-value {
  font-size: 3rem;
  color: #2ecc71;
}

.donation-item-amount-label {
  color: #222;
  padding: 0.5rem;
  text-align: center;
  font-weight: bold;
}

.donation-item-header {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 20px;
}

@media screen and (max-width: 900px) {
  .donation-item-body {
    flex-direction: column;
  }

  .donation-item-text-container {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .donation-item-image {
    width: 8px;
    height: 70px;
  }

  .donation-item-image-header {
    width: 80px;
    height: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .donation-sponsor-item-image {
    display: block;
    height: 25px;
    width: auto;
    align-self: center;
    margin-right: auto;
    margin-left: auto;
  }

  .donation-item {
    display: block;
    margin-bottom: 1rem;
    text-decoration: none;
  }

  .donation-item-body {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    border-radius: 0.5rem;
    padding: 1rem;
    cursor: pointer;
    border: 1px solid $white-gray;
    box-shadow: $sm-box-shadow;
    transition: all 120ms;

    &:hover {
      border-color: $light-blue;
    }
  }

  .donation-item-image-container {
    display: flex;
    flex: 2;
    align-items: center;
    flex-direction: column;
  }

  .donation-item-text-container {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .donation-item-amount-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    justify-self: center;
    align-self: stretch;
  }

  .donation-item-amount-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .donation-item-amount-value {
    font-size: 2rem;
    color: #2ecc71;
  }

  .donation-item-amount-label {
    color: #222;
    padding: 0.5rem;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
  }

  .donation-item-header {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 16px;
  }


}



.donation-list-loading-container {
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

//Sponsor tiles

.donation-sponsors {
  justify-content: space-evenly;
  padding: 15px;
  cursor: pointer;
  transition: all 120ms;
  flex-wrap: nowrap;
  border: 1px solid $white-gray;
  box-shadow: $sm-box-shadow;
}

.donation-sponsor-header-container {
  justify-content: space-around;
  align-items: center;
  font-size: 1.5rem;
  border: 1px solid $white-gray;
}

.donation-item-header-labels {
  color: #222;
  padding: 0.25rem;
  text-align: center;
}

.donation-sponsor-item-body {
  align-items: center;
  justify-content: space-between;
}

.donation-sponsor-title {
  align-items: center;
}

.donation-sponsor-item-text-container {
  align-self: stretch;
}

.donation-sponsor-item-amount-container {
  align-items: center;
  justify-content: space-evenly;
  align-self: stretch;
}

.donation-sponsor-item-amount-tile {
  align-items: center;
  text-align: center;
  padding: 1rem;
}

.donation-sponsor-item-amount-value {
  font-size: 1.5rem;
  color: #2ecc71;
}

.donation-sponsor-item-header {
  margin-top: 10px;
  text-align: center;
}

.donation-sponsor-item-amount-label {
  color: #222;
  padding: 0.25rem;
  text-align: center;
}

.donation-sponsor-item-image {
  display: block;
  height: 45px;
  width: auto;
  align-self: center;
  margin-right: auto;
  margin-left: auto;
}

table {
  width: 100%;
}

.redText {
  color: '#FF0000';
}
