$off-white: #fefefe;

$bg-gray: #f5f7fb;

$white-gray: #ddd;

$grayed-out: #767676;

$dark-gray: #555;
$dark-gray-shade: #494949;

$lightest-black: #444;
$lighter-black: #333;
$light-black: #222;
$dark-black: #000;


$light-blue: #3498db;
$light-blue-shade: #2f89c5;

$dark-blue: #2980b9;

$light-red: #e74c3c;

$blue-gray: #34495e;

$light-green: #2ecc71;
$dark-green: #27ae60;

$gold: #f1c40f;
$gold-shade: #e5ba0e;

$light-purple: #9b59b6;
$dark-purple: #8e44ad;

$light-turquoise: #1abc9c;
$dark-turquoise: #16a085;

$facebook-blue: #3c5a99;
$twitter-blue: #1da1f2;
$instagram-purple: #833ab4;
