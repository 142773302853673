.table-controls {
  display: flex;

  .form-group {
    flex: 1;
  }

  .form-group:first-child {
    margin-right: 2rem;
  }

  .rc-slider {
    margin-top: 11px;
  }
}

.ReactTable {
  max-height: calc(100% - 5rem);
  max-width: 1024px;
  background-color: white;
  color: black;
  border-radius: 3px;

  .-pagination {
    border-top: 0;
  }
}

@media screen and (max-width: 650px) {
  .table-controls {
    flex-direction: column;

    .form-group:first-child {
      margin-right: 0;
    }
  }
}
