@import './range-input-original';

.slider-label-container {
  display: flex;
  align-items: center;
}

.slider-val-container {
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: stretch;
  overflow: hidden;
  flex: 0 0 auto;
}

.slider-val-input,
.slider-val {
  border: 0;
  outline: none;
  padding: 2px 6px;
  width: 5rem;
  text-align: center;
}

.slider-val-unit {
  display: inline-flex;
  align-items: center;
  padding: 2px 6px;
  line-height: 1;
  font-weight: 400;
}
