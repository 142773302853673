@import 'styles/colors';
@import 'styles/general';

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $lighter-black;
  font-weight: bold;
}

p,
li {
  color: #444;
  line-height: 1.5;
  font-size: 1.125rem;
}

a.wrap-link {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
}

.App {
  background: $bg-gray;
  padding-top: $header-height;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.NoHeaderApp {
  background: $bg-gray;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

@media only screen and (max-width: 650px) {
  h1 {
    font-size: 1.5rem;
    line-height: 1.25;
  }

  h2 {
    font-size: 1.375rem;
  }

  p,
  li {
    font-size: 1rem;
  }
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &[type='text'] {
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
  }
}

input {
  margin-top: 0.25rem;
}

p {

  a,
  a:visited {
    overflow-wrap: break-word;
    word-wrap: break-word;
    color: #009dff;
    text-decoration: none;

    &:hover {
      color: #009dff;
      border-bottom: 2px solid;
    }
  }
}

input[type="email"],
input[type="password"] {
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

input[type="submit"] {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: #01d28e;
  border: 1px solid #01d28e;
  color: #fff;
  padding: 10px 20px;
}

input[type="submit"]:hover {
  background: #6cf0c2;
}


.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}

.login-page {
  align-items: center;
  align-content: center;
  align-self: center;
  justify-self: center;
}
