@import '../../styles/colors';
@import '../../styles/general';

#header {
  position: fixed;
  height: $header-height;
  top: 0;
  left: 0;
  right: 0;
  background: $off-white;
  box-shadow: $header-box-shadow;
  display: flex;
  align-items: center;
  z-index: 100;

  &.redeem {
    box-shadow: none;
    border-bottom: 1px solid $white-gray;
  }

  .header-logo {
    margin-left: 1.5rem;
    height: 4.25rem;
  }

  img {
    height: 2.5rem;
  }
}

.user-name {
  font-size: 1rem;
  margin-left: 0.5rem;
  color: #222;
}

.header-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
}

.header-content {
  margin: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .left-content,
  .right-content {
    display: inline-flex;
    align-items: center;
    margin: 0.5rem
  }
}

.home-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #222;

  .header-text {
    font-size: 1.25rem;
    margin-left: 1rem;
    font-weight: 700;
    line-height: 1;
  }
}

.hamburger-button {
  flex: 0 0 auto;
}

// @media only screen and(max-width: 850px) {
//   .home-button {
//     display: none;
//   }
// }

@media only screen and (max-width: 650px) {
  .header-content {
    margin: 0 0rem;
  }

  .header-text {
    display: none;
  }

  .home-button {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #222;

    .header-text {
      font-size: 1rem;
      margin-left: 1rem;
      font-weight: 700;
      line-height: 1;
    }
  }
}
